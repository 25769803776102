.loading {
}

.error {
  color: #dd5555;
}

form {
  text-align: center;
}

input {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  border-top: none;
  border-left: none;
  border-right: none;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

input:focus {
  outline: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.5);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.5);
}

button {
  background: none;
  border: none;
  color: #fff;
  padding: 4px;
  width: 32px;
  height: 32px;
  margin-left: 12px;
}

button:focus {
  outline: none !important;
}

div.form {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
