body {
  background: #17263c;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

p {
  text-transform: uppercase;
}

.n {
  display: inline-block;
  font-weight: 700;
  padding-right: 9px;
  text-align: right;
  width: 36px;
}
