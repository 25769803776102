body {
  background: #17263c;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

p {
  text-transform: uppercase;
}

.n {
  display: inline-block;
  font-weight: 700;
  padding-right: 9px;
  text-align: right;
  width: 36px;
}

.App {
  text-align: center;
}

.logo {
  margin-bottom: 30px;
  margin-left: -10px;
}

.loading {
}

.error {
  color: #dd5555;
}

form {
  text-align: center;
}

input {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  border-top: none;
  border-left: none;
  border-right: none;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

input:focus {
  outline: none !important;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}

::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.5);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.5);
}

button {
  background: none;
  border: none;
  color: #fff;
  padding: 4px;
  width: 32px;
  height: 32px;
  margin-left: 12px;
}

button:focus {
  outline: none !important;
}

div.form {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

